window.esign = window.esign || {};

esign.init = function () {

  $('html').removeClass('no-js').addClass('js');

  Response.create({
    prop: "width",
    prefix: "min-width- r src",
    breakpoints: [752,0],
    lazy: true
  });

  esign.gaDevelopment();
  esign.cacheSelectors();

  esign.navigation();
  esign.responsiveVideos();

  esign.initMaps();

  esign.formValidation();
  esign.formAjax();
  esign.formAjaxRecaptcha();

  esign.initFloatingLabels();
  esign.initImageLoad();
  esign.initSlickSlider();
  esign.initScrollHandler();
  esign.scrollOnPageLinks();
  esign.initAnimations();
  esign.initGDPR();
};

esign.cacheSelectors = function () {
  esign.cache = {
    // general
    isMobile: esign.isMobile(),
    $body: $('body'),
    $mobileIndicator: $('.mobile-indicator'),

    // navigation
    $nav: $('.main-nav__wrap'),

    $pageHeader: $('.page-header')
  };
};

esign.navigation = function () {
  $('.main-nav__trigger').click(function(e) {
    e.preventDefault();
    var $mainNavWrap = $(this).next('.main-nav__wrap');
    if(parseInt($mainNavWrap.css('width')) === 0) {
      $mainNavWrap.addClass('open-mobile');
    } else {
      $mainNavWrap.removeClass('open-mobile');
    }
  });
};

esign.responsiveVideos = function () {
  $('iframe[src*="youtube.com/embed"], iframe[src*="youtube-nocookie.com/embed"], iframe[src*="player.vimeo"]').wrap('<div class="video-container"></div>');
};


// ---------------------------------------
// ----------------- Maps ----------------
// ---------------------------------------

function addContactGoogleMaps(container, latitude, longitude) {

  var zoom = 15,
    disable = true,
    scroll = false,
    styledMap = new google.maps.StyledMapType(googleMapsStyles, {name: 'Styled Map'}),
    mapCenter = new google.maps.LatLng(latitude, longitude),
    mapOptions = {
      zoom: zoom,
      panControl: true,
      zoomControl: disable,
      scaleControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      overviewMapControl: false,
      minZoom: 2,
      scrollwheel: scroll,
      center: mapCenter,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    },
    map = new google.maps.Map(document.getElementById(container), mapOptions);

  map.mapTypes.set('map_style', styledMap);
  map.setMapTypeId('map_style');

  google.maps.event.addDomListener(window, 'resize', function () {
    map.setCenter(mapCenter);
  });

  return map;
}

function addContactGoogleMapsMarker(map, latitude, longitude, title, externUrl) {
  var myLatlng = new google.maps.LatLng(latitude, longitude),
    markerIcon = {
      url: baseUrl + 'assets/images/marker.png',
      size: new google.maps.Size(100, 128),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(25, 64),
      scaledSize: new google.maps.Size(50, 64)
    },
    marker = new google.maps.Marker({
      position: myLatlng,
      map: map,
      icon: markerIcon
    });

  google.maps.event.addListener(marker, 'click', function () {
    window.open(externUrl, '_blank');
  });

  return marker;
}

esign.initMaps = function () {
  var mapData = {
    title: 'Esign',
    lat: 51.0325538,
    long: 3.7333816,
    externUrl: 'https://www.google.be/maps/place/Esign+-+Web+%26+Graphics/@51.0325538,3.7333816,19z/data=!3m1!4b1!4m5!3m4!1s0x47c373970c763623:0xde317546f86febc9!8m2!3d51.0325538!4d3.7339288'
  };

  if ($('#map').length > 0) {
    var map = addContactGoogleMaps('map', mapData.lat, mapData.long);
    addContactGoogleMapsMarker(map, mapData.lat, mapData.long, mapData.title, mapData.externUrl);
  }
};


// ---------------------------------------
// ----------------- FORMS & Captcha -----
// ---------------------------------------

// normaal formulier versturen met post
esign.formAjax = function () {
  $('.form-ajax').submit(function(e) {
    var $form = $(this);
    $form.find('button,input[type="submit"]').attr("disabled", "disabled");

    $.post($form.attr('action'), $form.serializeArray(), function(data) {
      if(data.errors === false) {
        $form.html(data.result);
      } else {
        $form.find('.result').html(data.result);
        $form.find('button, input[type="submit"]').removeAttr('disabled');
      }
    });

    e.preventDefault();
    return false;
  });
};

// recaptcha formulier versturen met post
esign.formAjaxRecaptcha = function () {
  $('.form-ajax-recaptcha').submit(function (e) {
    e.preventDefault();
    var $form = $(this);

    if ($form.hasClass('validate')) {
      if ($form.validationEngine('validate') == false) {
        return false;
      }
    }

    if ($form.find('[name="g-recaptcha-response"]').length && $form.find('[name="g-recaptcha-response"]').val() != '') {
      grecaptcha.reset();
    }

    grecaptcha.execute($form.find('.g-recaptcha').data('widgetid'));

    return false;
  });
};

// recaptcha submit handler
esign.recaptchaFormSend = function (form) {
  $form = $(form);
  $form.find('input[type="submit"], button').attr('disabled', 'disabled');

  $.ajax({
    type: 'POST',
    url:$form.attr('action'),
    data: new FormData($form[0]),
    processData: false,
    // Allows us to get file fields via JS
    contentType: false,
    success: function(data) {
      $('li').removeClass('error');
      var $result = $form.find('.result');

      if (data.errors === false) {
        $form.html(data.result);

        if (typeof ga === "function") {
          ga('send', 'pageview', $form.attr('action'));
        }

        //todo GTM trigger event

      } else {
        $result.html(data.result);
        if (data.fields) {
          $.each(data.fields, function (i, field) {
            $('input[name="' + field + '"],textarea[name="' + field + '"]').addClass('error');
          });
        }
      }

      $form.find('input[type="submit"], button').removeAttr('disabled');
    }
  }).fail(function (response) {
    alert('Error: ' + response.responseText);
  });
};

// recaptcha submit handler per formulier
var submitRecaptchaFormContact = function (token) {
  esign.recaptchaFormSend($('#form-contact'));
};
var submitRecaptchaFormNewsletter = function (token) {
  esign.recaptchaFormSend($('#form-newsletter'));
};

// explicit recaptcha methode (zodat er meerdere op 1 pagina kunnen)
var onloadReCaptchaCallback = function () {
  $('.g-recaptcha').each(function (i, el) {
    var attributes = {
      'sitekey': $(el).data('sitekey'),
      'size': $(el).data('size'),
      'callback': $(el).data('callback')
    };

    var widgetid = grecaptcha.render(el, attributes);
    $(el).data('widgetid', widgetid);
  });
};


// ---------------------------------------
// ----------------- FORMS validation ----
// ---------------------------------------

esign.formValidation = function () {
  if(esign.cache.isMobile) {
    $('.validate').validationEngine();
  } else {
    $('.validate').validationEngine({scroll: false});
  }
};


// ---------------------------------------
// ----------------- UTILS -----------
// ---------------------------------------
esign.isMobile = function () {
  var deviceAgent = navigator.userAgent.toLowerCase(),
      isMobile = (deviceAgent.match(/(iphone|ipod|ipad)/) ||
        deviceAgent.match(/(android)/)  ||
        deviceAgent.match(/(iemobile)/) ||
        deviceAgent.match(/blackberry/i) ||
        deviceAgent.match(/bada/i)) ||
        (/OS [1-4]_[0-9_]+ like Mac OS X/i.test(navigator.userAgent));

  if(isMobile) {
    $('html').addClass('mobile');
  } else {
    $('html').addClass('no-mobile');
  }

  return isMobile;
};


// ---------------------------------------
// ----------------- DEV TOOLS -----------
// ---------------------------------------

// Log ga calls in development
esign.gaDevelopment = function() {
  if (typeof ga === typeof undefined) {
    window.ga = function() {
      var argumentsArray = [];
      for (var key in arguments) {
        if (arguments.hasOwnProperty(key)) {
          argumentsArray.push(arguments[key]);
        }
      }

      var msg = '[GA DEV] Call with arguments [' + argumentsArray.join(',') + ']';
      console.log('%c' + msg, 'background: #ff9800; color: #fff;');
    };
  }
};


esign.initSlickSlider = function() {
  $('.slider').each(function() {
    var nrOfSlides = 3;
    if($(this).data('nr-visible') != null) {
      nrOfSlides = parseInt($(this).data('nr-visible'));
    }

    $(this).lightGallery({
      'speed': 200
    });

    $(this).on('init', function() {
      $(this).addClass('js-initialized');
    });

    var slick = $(this).slick({
      slidesToShow: nrOfSlides,
      infinite: true,
      swipe: true,
      draggable: false,
      adaptiveHeight: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });

    // actions
    var actionsId = $(this).data('actions');
    if (actionsId) {
      var $actions = $(actionsId);
      var $actionPrev = $actions.find('.slider-action--prev');
      var $actionNext = $actions.find('.slider-action--next');

      $actionPrev.click(function() {
        slick.slick("slickPrev");
      });

      $actionNext.click(function() {
        slick.slick("slickNext")
      });
    }
  });
};

var previousScrollY = 0;
var lastScrollYWhileScrollingDown = 0;
esign._menuChangeByScrollPos = function(scrollY) {
  var scrollDirection = scrollY > previousScrollY ? 'down' : 'up';
  previousScrollY = scrollY;

  if(scrollY > 40) {
    esign.cache.$pageHeader.addClass('page-header--include-background');
  } else {
    esign.cache.$pageHeader.removeClass('page-header--include-background');
  }

  if(scrollY > 125 && scrollDirection === 'down') {
    esign.cache.$pageHeader.addClass('page-header--hide');
    lastScrollYWhileScrollingDown = scrollY;
  } else {
    if(lastScrollYWhileScrollingDown - scrollY > 120) {
      esign.cache.$pageHeader.removeClass('page-header--hide');
    }
  }
};

esign.initScrollHandler = function() {
  $(window).scroll(function() {
    var scrollY = ($(window).scrollTop() || $('body').scrollTop());
    esign._menuChangeByScrollPos(scrollY);
  });
  $(window).scroll();
};

esign.scrollOnPageLinks = function() {
  $("a[href*=\\#]:not(.tab-control, .modal-trigger)").click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'')
      && location.hostname == this.hostname) {
      var $target = $(this.hash);
      if(this.hash.slice(1)) {
        $target = $target.length && $target || $('[name=' + this.hash.slice(1) +']');
        if ($target.length) {
          var padding = 0;
          $('html, body').animate({
            scrollTop: $target.offset().top - padding
          }, 600);
          return false;
        }
      }
    };
  });

  $(window).on('load', function() {
    $('.js-scroll-down').show();
  });
};

esign.initAnimations = function() {
  var elems;
  var windowHeight;
  var animationEventHandlersAdded = false;
  function init() {
    elems = document.querySelectorAll('.reveal-on-scroll, .h-line, .h-line-left');
    windowHeight = window.innerHeight;
    addEventHandlers();
    checkPosition();
  }
  function addEventHandlers() {
    if(!animationEventHandlersAdded) {
      window.addEventListener('scroll', checkPosition);
      window.addEventListener('resize', init);
    }
  }
  function checkPosition() {
    for (var i = 0; i < elems.length; i++) {
      var positionFromTop = elems[i].getBoundingClientRect().top;
      var offset = 150;
      if(esign.cache.$mobileIndicator.is(':visible')) {
        offset = 40;
      }
      if (positionFromTop - windowHeight <= -offset) {
        elems[i].classList.add(
          'js-enter-animation'
        );
      }
    }
  }

  init();
};

esign.initImageLoad = function() {
  esign.cache.$body.imagesLoaded().progress(function(instance, image) {
    $(image.img).addClass('js-loaded');
  });

  $('.visual__img').imagesLoaded({background: true}).progress(function(instance, image) {
    $(image.element).addClass('js-loaded');
  });
};

esign.initGDPR = function() {
  $("#gdpr").each(function() {
    var $submit = $(this).closest('form').find('input[type="submit"], button[type="submit"]');
    $(this).change(function() {
      if($(this).is(':checked')) {
        $submit.removeAttr('disabled');
      } else {
        $submit.attr('disabled', 'disabled');
      }
    });
  });
};

esign.initFloatingLabels = function(query) {
  if(!query) {
    query ='input, textarea';
  }
  var $inputs = $(query);
  $inputs.change(function() {
    if($(this).val() && $(this).val() !== '') {
      $(this).addClass('float-label');
    } else {
      $(this).removeClass('float-label');
    }
  })
};


require([
  'requirejs/require',
  'jquery/dist/jquery',
  'js/libs/plugins/slick.min',
  'js/libs/plugins/lightgallery.min',
  'js/libs/plugins/imagesloaded.pkgd.min',
  'js/polyfills/native-console',
  'response.js/response',
  'jquery-touchswipe/jquery.touchSwipe',
  'js/libs/validation/languages/jquery.validationEngine-nl',
  'js/libs/validation/jquery.validationEngine',
  'js/googlemaps-styles'
], function() {
  // Initialize on docready
  $(esign.init);
});

define("../resources/assets/js/esign", function(){});

